import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import Contact from '@components/contact';
import Hero from '../components/hero';
import Layout from '@components/layout';
import URLSection from '@components/index-components/urlSection';

import * as styles from './nordiska.module.css';

const Nordiska = ({ data }) => {
  const headLine = data.contentfulPage.headLine;
  const headerTitle = data.contentfulPage.pageHeader;
  const lists = data.contentfulPage.pageContent[0].links.lists;

  return (
    <Layout newStyle>
      <SEO title={headLine} />
      <Hero title={headerTitle} />
      <div className="container">
        <div className={styles.urlSectionsContainer}>
          <URLSection lists={lists} />
        </div>
      </div>
      <Contact />
    </Layout>
  );
};

export default Nordiska;

Nordiska.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query NordiskaVillkorHomeQuery {
    contentfulPage(pageUrl: { eq: "/nordiska" }) {
      headLine
      pageHeader
      pageContent {
        ... on ContentfulLinkSection {
          links {
            lists {
              header
              urls {
                href
                text
              }
            }
          }
          header
        }
      }
    }
  }
`;
